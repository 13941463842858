<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="nodeToBind"
    >
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item prop="node_name" :label="__('Name')">
            <el-input v-model="nodeToBind.node_name"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col>
          <el-form-item
            prop="check_waiter_node.data.queue_id"
            :label="__('Select queue')"
          >
            <el-select
              v-model="nodeToBind.check_waiter_node.data.queue_id"
              v-loading="isLoading"
              default-first-option
              filterable
              :placeholder="__('Please select a queue')"
              style="width: 100%;"
              ><template slot="prefix"
                ><img
                  style="vertical-align: middle"
                  :src="
                    getRowIconById(nodeToBind.check_waiter_node.data.queue_id)
                  "
              /></template>
              <el-option-group
                v-for="group in generateQueueOptions"
                :key="group.label"
                :label="group.label"
              >
                <el-option
                  v-for="item in group.options"
                  :key="item.queue_id"
                  :label="item.queue_name"
                  :value="item.queue_id"
                >
                </el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col>
          <el-form-item
            prop="check_waiter_node.data.waiter_token_variable.variable_id"
            :label="__('Check waiter token')"
          >
            <create-or-select
              :items="generateVariableOptions"
              label="variable_name"
              value="variable_id"
              :current_select="variableSelected('waiter_token_variable')"
              :placeholder="__('Select variable or type a new name')"
              :new-item-message="__('new variable')"
              style="width: 100%;"
              @change="variableSelectionChange($event, 'waiter_token_variable')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col>
          <el-form-item
            prop="check_waiter_node.data.response_code_variable.variable_id"
            :label="__('Response code')"
          >
            <create-or-select
              :items="generateVariableOptions"
              label="variable_name"
              value="variable_id"
              :current_select="variableSelected('response_code_variable')"
              :placeholder="__('Select variable or type a new name')"
              :new-item-message="__('new variable')"
              style="width: 100%;"
              @change="
                variableSelectionChange($event, 'response_code_variable')
              "
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col>
          <el-form-item
            prop="check_waiter_node.data.response_message_variable.variable_id"
            :label="__('Response message')"
          >
            <create-or-select
              :items="generateVariableOptions"
              label="variable_name"
              value="variable_id"
              :current_select="variableSelected('response_message_variable')"
              :placeholder="__('Select variable or type a new name')"
              :new-item-message="__('new variable')"
              style="width: 100%;"
              @change="
                variableSelectionChange($event, 'response_message_variable')
              "
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import BaseNode from "../BaseNode";
import _ from "lodash";
import { NODE_TYPES } from "@/constants/nodes";
import CreateOrSelect from "../components/CreateOrSelect";
import { mapActions, mapGetters, mapState } from "vuex";
// import { filterRowsIfSomeKeyValueIsAbsent, getSubKeyObject } from "@/utils/collection";

export default {
  mixins: [BaseNode],
  components: {
    CreateOrSelect
  },
  data() {
    return {
      rules: {
        check_waiter_node: {
          data: {
            queue_id: {
              required: true,
              message: __("Please select a queue"),
              trigger: "blur"
            },
            waiter_token_variable: {
              variable_id: {
                required: true,
                message: __("Please select a variable for waiter token"),
                trigger: "blur"
              }
            },
            response_code_variable: {
              variable_id: {
                required: true,
                message: __("Please select a variable for response code"),
                trigger: "blur"
              }
            }
          }
        }
      }
    };
  },
  computed: {
    ...mapState("qforme", {
      queues: state => state.queues,
      isLoading: state => state.loading
    }),

    ...mapGetters("qforme", {
      queue: "queue"
    }),

    generateVariableOptions() {
      return _.filter(this.variables, variable => {
        return !["array", "audio", "system", "payment", "secure"].includes(
          variable.variable_type
        );
      });
    },

    generateQueueOptions() {
      let standard = _.filter(this.queues, function(o) {
        return o.ac_plan.data[0].plan_type === "QforMe Standard";
      });
      let premium = _.filter(this.queues, function(o) {
        return o.ac_plan.data[0].plan_type === "QforMe Premium";
      });
      // return standard;
      return [
        {
          label: __("Standard"),
          options: standard
        },
        {
          label: __("Premium"),
          options: premium
        }
      ];
    }
  },
  methods: {
    ...mapActions("qforme", {
      getQueues: "getQueues"
    }),

    variableSelected(var_name) {
      let var_object = this.nodeToBind.check_waiter_node.data[var_name];
      if (!var_object) {
        return "";
      } else {
        const { variable_id, variable_name } = var_object;
        return variable_id === -1 ? variable_name : variable_id;
      }
    },

    variableSelectionChange(option, var_name) {
      this.newVariableCreated = option.value === -1;
      let var_object = {
        variable_id: option.value,
        variable_name: option.label
      };
      if (_.isEmpty(var_object.variable_name)) {
        var_object.variable_id = "";
      }
      this.$set(this.nodeToBind.check_waiter_node.data, var_name, var_object);
    },

    cleanUpNodeToPrepareForSubmit() {
      const nodeToCleanUp = _.cloneDeep(this.nodeToBind);
      return nodeToCleanUp;
    },

    getRowIcon(queue) {
      if (queue) {
        if (queue.ac_plan.data[0].plan_type === "QforMe Standard") {
          return require("@/assets/icons//icon-waiter.svg");
        } else if (queue.ac_plan.data[0].plan_type === "QforMe Premium") {
          return require("@/assets/icons//icon-premium-waiter.svg");
        }
      }
    },

    getRowIconById(queue_id) {
      let queue = _.find(this.queues, ["queue_id", queue_id]);
      return this.getRowIcon(queue);
    },

    cleanUpNode() {
      if (!this.formHasErrors) {
        this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
        this.createOrEditNode();
      } else {
        this.toggleNodeSubmit(false);
      }
    }
  },
  created() {
    if (
      !this.nodeToBind.node_id ||
      _.isEmpty(this.nodeToBind.check_waiter_node)
    ) {
      // console.log(this.nodeToBind);
      this.$set(this.nodeToBind, "check_waiter_node", {});
      this.$set(this.nodeToBind.check_waiter_node, "data", {});

      this.$set(this.nodeToBind.check_waiter_node.data, "queue_id", null);
      this.$set(
        this.nodeToBind.check_waiter_node.data,
        "response_code_variable",
        null
      );
      this.$set(
        this.nodeToBind.check_waiter_node.data,
        "response_message_variable",
        null
      );
      this.$set(
        this.nodeToBind.check_waiter_node.data,
        "waiter_token_variable",
        null
      );

      this.$set(
        this.nodeToBind,
        "node_type",
        NODE_TYPES.CHECK_WAITER.NODE_TYPE
      );
    }
  },
  mounted() {
    this.getQueues();
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/node_common.scss";
.tabPane {
  max-height: 52vh;
  padding-right: 30px;
}
</style>
